import Home from "./Pages/Home/Home";
import LegalNotice from "./Pages/LegalNotice/LegalNotice";
import GeneralConditions from "./Pages/GeneralConditions/GeneralConditions";
import Explorer from "./Pages/Explorer/Explorer";
import About from "./Pages/About/About";
import { Routes, Route, useLocation } from "react-router-dom";
import ModalMail from "./Components/ModalMail/ModalMail";
import "./App.scss";
import NavBar from "./Components/NavBar/NavBar";
import Footer from "./Components/Footer/Footer";
import ScrollToTop from "./Utils/ScrollToTop";

export default function App() {

  const location = useLocation()
  return (
    <div className="App">
      <NavBar />
      <ScrollToTop />
      <ModalMail />
      <Routes>
        <Route index element={<Home />} />
        <Route path="legal-notice" element={<LegalNotice />} />
        <Route path="general-conditions" element={<GeneralConditions />} />
        <Route path="about" element={<About />} />
        <Route path="*" element={<Home />} />
      </Routes>
      {location.pathname !== '/explorer/' && <Footer /> }
    </div>
  );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { postSubscribe, getDepartures } from './app.thunk'

export enum SnackbarSeverity {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

interface AppState {
  error: null | undefined,
  displaySnackbar: boolean,
  snackbarMessage: string,
  snackbarSeverity: SnackbarSeverity,
  mailModalOpened: boolean,
  mailModalTitle: string,
  mailModalSubTitle: string,
  subscribeSucceded: boolean,
  departuresResults: any
}

const initialState: AppState = {
  error: null,
  displaySnackbar: false,
  snackbarMessage: '',
  snackbarSeverity: SnackbarSeverity.SUCCESS,
  mailModalOpened: false,
  mailModalTitle: 'modalSubscribe.title',
  mailModalSubTitle: 'modalSubscribe.subtitle',
  subscribeSucceded: false,
  departuresResults: undefined
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleMailModal: (state, action: PayloadAction<boolean>) => {
      state.mailModalOpened = action.payload
    },
    getError: (state, action: PayloadAction<null>) => {
      state.error = action.payload
    },
    setSnackbar: (state, action: PayloadAction<{ message: string, severity: SnackbarSeverity }>) => {
      console.log('seterror')
      state.displaySnackbar = true
      state.snackbarMessage = action.payload.message
      state.snackbarSeverity = action.payload.severity
    },
    hideSnackbar: state => {
      state.displaySnackbar = false
      state.snackbarMessage = ''
      state.snackbarSeverity = SnackbarSeverity.SUCCESS
    },
    setSubscribeSucceeded: (state, action: PayloadAction<boolean>) => {
      state.subscribeSucceded = action.payload
    },
    resetMailModal: state => {
      state.mailModalTitle = initialState.mailModalTitle
      state.mailModalSubTitle = initialState.mailModalSubTitle
    }
  },
  extraReducers: builder => {
    builder.addCase(postSubscribe.fulfilled, (state, action) => {
      state.subscribeSucceded = true
      if (action.payload.message === 'subscribe') {
        state.mailModalTitle = 'modalSubscribe.titleSubscribe'
        state.mailModalSubTitle = 'modalSubscribe.subtitleSubscribe'
      } else {
        state.mailModalTitle = 'modalSubscribe.titleSubscribed'
        state.mailModalSubTitle = 'modalSubscribe.subtitleSubscribed'
      }
    })
    builder.addCase(getDepartures.fulfilled, (state, action) => {
      state.departuresResults = action.payload.data
    })
  },
})

export const {
  toggleMailModal,
  resetMailModal,
  getError,
  setSnackbar,
  hideSnackbar,
  setSubscribeSucceeded
} = appSlice.actions

export default appSlice.reducer

import MainBanner from "../../Components/MainBanner/MainBanner";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toggleMailModal } from "../../reducers/app";
import img1 from "../../Assets/Img/img-home-1.png";
import img2 from "../../Assets/Img/img-home-2.png";
import img3 from "../../Assets/Img/img-home-3.png";
import "./Home.scss";

export default function Home() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <div
      className="d-flex justify-content-center bg-black flex-column align-items-center"
      style={{ paddingTop: 64 }}
    >
      <MainBanner />
      <div
        className="w-full bg-white d-flex justify-content-center"
      >
        <div
          className="mid-container d-flex flex-column px-s"
          style={{ paddingTop: 70, paddingBottom: 70 }}
        >
          <div className="w-full d-flex flex-s-column">
            <div className="flex-basis-50">
              <img src={img1} alt="" />
            </div>
            <div className="flex-basis-50 d-flex flex-column justify-content-center pl-70 pl-s-0">
              <div className="text-48 text-bold white-space-pre">
              {t('home.section1.title')}
              </div>
              <div className="mt-s-24 mt-60"
                style={{
                  marginBottom: 40,
                  textAlign: "justify",
                  lineHeight: "1.4",
                }}
              >
                {t('home.section1.subtitle')}
              </div>
              <button
                className="button-primary-pink text-semi-bold"
                onClick={() => dispatch(toggleMailModal(true))}
              >
                {t('common.explore')}
              </button>
            </div>
          </div>

          <div
            className="w-full d-flex flex-row-reverse flex-s-column"
            style={{ paddingTop: 70, paddingBottom: 70 }}
          >
            <div className="flex-basis-50">
              <img src={img2} alt="" />
            </div>
            <div className="flex-basis-50 d-flex flex-column justify-content-center pr-70 pr-s-0">
              <div className="text-48 text-bold white-space-pre">
              {t('home.section2.title')}
              </div>
              <div className="mt-s-24 mt-60"
                style={{
                  marginBottom: 40,
                  textAlign: "justify",
                  lineHeight: "1.4",
                }}
              >
                {t('home.section2.subtitle')}
              </div>
              <button
                className="button-primary-pink text-semi-bold"
                onClick={() => dispatch(toggleMailModal(true))}
              >
                {t('common.book')}
              </button>
            </div>
          </div>

          <div className="w-full d-flex flex-s-column">
            <div className="flex-basis-50">
              <img src={img3} alt="" />
            </div>
            <div className="flex-basis-50 d-flex flex-column justify-content-center pl-70 pl-s-0">
              <div className="text-48 text-bold white-space-pre">
              {t('home.section3.title')}
              </div>
              <div
              className="mt-s-24 mt-60"
                style={{
                  marginBottom: 40,
                  textAlign: "justify",
                  lineHeight: "1.4",
                }}
              >
                {t('home.section3.subtitle')}
              </div>
              <button
                className="button-primary-pink text-semi-bold"
                onClick={() => dispatch(toggleMailModal(true))}
              >
                {t('common.travel')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import "./MainBanner.scss";
import { RootState } from "../../reducers/store";
import { useSelector, useDispatch } from "react-redux";
import { toggleMailModal } from "../../reducers/app";
import bannerImg from "../../Assets/Img/banner.png";
import icon from "../../Assets/Icon/ellipse.svg";
import swap from "../../Assets/Icon/swap-vertical.svg";
import topRightCorner from "../../Assets/Icon/top-right.svg";
import bottomLeftCorner from "../../Assets/Icon/bottom-left.svg";


import { useTranslation } from "react-i18next";

export default function MainBanner() {
  const { mailModalOpened } = useSelector((state: RootState) => state.app);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <div
      className="w-full bg-black text-white d-flex justify-content-center align-items-center"
      style={{
        paddingTop: 70,
        paddingBottom: 70,
        height: 'calc(100vh - 204px)',
        minHeight: '500px',
        maxHeight: '1080px' }}
    >
      
      <div className="mid-container w-full d-flex p-relative h-full justify-content-between" style={{ maxHeight: '650px'}}>
        <div className="flex-basis-50 px-s flex-s-basis-100 d-flex flex-column justify-content-center">
        <img className="main-banner-bottom-left-corner d-s-none" src={bottomLeftCorner} width={100} alt="ticket-please" />

          <div className="text-48 text-bold white-space-pre p-relative">
            {t("mainBanner.title")}
            <img className="main-banner-top-right-corner-mobile d-s-display" src={topRightCorner} width={100} alt="ticket-please" />
          </div>
          <div className="text-20" style={{ marginTop: 50, marginBottom: 30 }}>
            {t("mainBanner.subtitle")}
          </div>
          <div className="main-banner-input">
            <div className="p-relative">
              <img className="main-input-icon" src={icon} alt="ticket-please" />
              <input
                type="text"
                className="main-input"
                placeholder={t("mainBanner.inputPlaceHolderStart")}
                onClick={() => dispatch(toggleMailModal(true))}
                disabled={mailModalOpened}
                style={{
                  paddingLeft: 38,
                  borderRadius: "10px 10px 0 0",
                }}
              />
              
            </div>
            <img className="main-banner-bottom-left-corner-mobile d-s-display" src={bottomLeftCorner} width={100} alt="ticket-please" />
            <div className="p-relative" style={{ marginTop: '5px'}}>
              <img className="main-input-icon" src={icon} alt="ticket-please" />
              <input
                type="text"
                className="main-input"
                placeholder={t("mainBanner.inputPlaceHolderEnd")}
                value={t("mainBanner.inputValueEnd")}
                onClick={() => dispatch(toggleMailModal(true))}
                disabled={mailModalOpened}
                style={{
                  paddingLeft: 38,
                  borderRadius: "0 0 10px 10px",
                }}
              />
              <img className="main-input-icon-swap" src={swap} alt="ticket-please" />
            </div>
            <button
              className="button-primary-pink text-semi-bold"
              style={{ marginTop: 20, width: '100%' }}
              onClick={() => dispatch(toggleMailModal(true))}
            >
              {t("common.explore")}
            </button>
          </div>
        </div>
        <div className="d-flex d-s-none align-items-center">
          <div className="p-relative h-full">
          <img src={bannerImg} alt="" />

          <img className="main-banner-top-right-corner" src={topRightCorner} width={100} alt="ticket-please" />

          </div>
        </div>
      </div>
    </div>
  );
}

/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import API_URL from '../config/config'

type GetParams = {
  email: string
}
const postSubscribe = createAsyncThunk(
  'app/postSubscribe',
  async (params: GetParams, thunkApi) => {
    try {
      const response = await axios.post(`${API_URL.url}/users/signup/`, params)
      if (response.status === 202) {
        return thunkApi.fulfillWithValue({
          message: 'subscribed'
        })
      } else {
        return thunkApi.fulfillWithValue({
          message: 'subscribe'
        })
      }
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getDepartures = createAsyncThunk(
  'app/getDepartures',
  async (id: string, thunkApi) => {
    try {
      const response = await axios.get(`${API_URL.url}/explore/departures/?q=${id}`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

export {
  postSubscribe,
  getDepartures
}

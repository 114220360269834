import { useDispatch } from 'react-redux'
import { toggleMailModal } from "../../reducers/app";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import mainLogo from "../../Assets/Logo/ticket-please-logo-white.svg";
import linkedIn from "../../Assets/Icon/linkedin-white.png";

import "./Footer.scss";


export default function Footer() {
  const dispatch = useDispatch()
  const { t } = useTranslation();


  const handleEmailClick = () => {
    const emailAdress = 'contact@ticket-please.com'
    const subject = 'Ticket Please'
    const body = ''

    const mailtoLink = `mailto:${emailAdress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
    window.location.href = mailtoLink
  }

  const handleLinkedInClick = () => {
    window.open("https://www.linkedin.com/company/ticket-please/", "_blank", "noreferrer");
  }

  return (
    <div
      className="bg-black d-flex justify-content-center text-white w-full"
      style={{
        minHeight: 225,
      }}
    >
      <div
        className="full-container w-full d-flex flex-s-column"
        style={{
          paddingLeft: 24,
          paddingRight: 24,
          paddingTop: 30,
          paddingBottom: 30,
        }}
      >
        <div className="flex-grow-1 d-flex flex-column h-full justify-content-between align-items-start align-item-s-center">
        <Link to="/"><img src={mainLogo} alt="ticket please" height="54" className="" /></Link>
          <div className="text-12">©2024 ticket Please</div>
        </div>
        <div className="flex-grow-1 d-flex flex-column gap-s-24 justify-content-evenly align-items-center">
          <div className="mt-s-24">{t('footer.joinOurCommunity')}</div>
          <div>
            <button
            onClick={() => dispatch(toggleMailModal(true))}
              className="main-input placeholder-center cursor"
              style={{
                width: 285,
              }}
            >{t('common.inputEmailPlaceholder')}</button>
          </div>
          <div className="mb-s-24">
            <img src={linkedIn} alt="ticket please" height="32" className="text-link" onClick={handleLinkedInClick}/>
          </div>
        </div>
        <div className="flex-grow-1 d-flex flex-column justify-content-end align-items-end align-item-s-center justify-content-s-center text-14">
          <div className="d-flex flex-column align-item-s-center">
            <div>
              <span onClick={handleEmailClick} className="text-link text-white text-12">{t('common.contactUs')}</span>
            </div>
            <div style={{ marginTop: 5, marginBottom: 5 }}>
              <Link className="text-link text-white text-12" to="/about">
              {t('common.about')}
              </Link>
            </div>
            <div style={{ marginBottom: 5 }}>
              <Link className="text-link text-white text-12" to="/legal-notice">
              {t('common.legalMention')}
              </Link>
            </div>
            <div>
              <Link className="text-link text-white text-12" to="/general-conditions">
              {t('common.generalConditions')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useTranslation } from "react-i18next";

export default function About() {
  const { t } = useTranslation();
  return (
    <div
      className="d-flex justify-content-center flex-column align-items-center"
      style={{ paddingTop: 64 }}
    >
      <div className="d-flex justify-content-center w-full">
        <div
          className="mid-container d-flex flex-column w-full px-s"
          style={{ paddingTop: 70, paddingBottom: 60, textAlign: "justify" }}
        >
          <div className="text-48 text-bold" style={{ paddingBottom: 35 }}>
            {t("about.section1.title")}
          </div>

          <div style={{ paddingBottom: 35 }}>
            {t("about.section1.subtitle1")}
          </div>

          <div style={{ paddingBottom: 35, maxWidth: 600 }}>
            {t("about.section1.subtitle2")}
          </div>

          <div style={{ maxWidth: 600 }}>{t("about.section1.subtitle3")}</div>
        </div>
      </div>
      <div className="bg-black d-flex justify-content-center w-full">
        <div
          className="mid-container d-flex text-white px-s flex-column w-full"
          style={{ paddingBottom: 40, paddingTop: 40 }}
        >
          <div className="text-48 text-bold" style={{ paddingBottom: 35 }}>
            {t("about.section2.title")}
          </div>
          <div className="d-flex" style={{ marginBottom: 45 }}>
            <div className="text-128" style={{ lineHeight: 0.8 }}>
              1
            </div>
            <div style={{ marginLeft: 10 }}>
              <div
                className="text-24 text-semi-bold"
                style={{ marginBottom: 12 }}
              >
                {t("about.section2.subtitle1")}
              </div>
              <div style={{ maxWidth: 635 }}>{t("about.section2.desc1")}</div>
            </div>
          </div>

          <div
            className="d-flex pl-s-0"
            style={{ marginBottom: 45, paddingLeft: 70 }}
          >
            <div className="text-128" style={{ lineHeight: 0.8 }}>
              2
            </div>
            <div style={{ marginLeft: 10 }}>
              <div
                className="text-24 text-semi-bold"
                style={{ marginBottom: 12 }}
              >
                {t("about.section2.subtitle2")}
              </div>
              <div style={{ maxWidth: 635 }}>{t("about.section2.desc2")}</div>
            </div>
          </div>

          <div className="d-flex pl-s-0" style={{ paddingLeft: 140 }}>
            <div className="text-128" style={{ lineHeight: 0.8 }}>
              3
            </div>
            <div style={{ marginLeft: 10 }}>
              <div
                className="text-24 text-semi-bold"
                style={{ marginBottom: 12 }}
              >
                {t("about.section2.subtitle3")}
              </div>
              <div style={{ maxWidth: 635 }}>{t("about.section2.desc3")}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center flex-column w-full">
        <div
          className="mid-container d-flex flex-column px-s "
          style={{ paddingTop: 70, paddingBottom: 35 }}
        >
          <div className="text-48 text-bold" style={{ paddingBottom: 35 }}>
            {t("about.section3.title")}
          </div>

          <div className="text-24 text-semi-bold">
            {t("about.section3.subtitle1")}
          </div>
          <div style={{ padding: "12px 24px 44px 0", textAlign: "justify" }}>
            {t("about.section3.desc1")}
          </div>

          <div className="text-24 text-semi-bold">
            {t("about.section3.subtitle2")}
          </div>
          <div style={{ padding: "12px 24px 44px 0", textAlign: "justify" }}>
            {t("about.section3.desc2")}
          </div>

          <div className="text-24 text-semi-bold">
            {t("about.section3.subtitle3")}
          </div>
          <div style={{ padding: "12px 24px 44px 0", textAlign: "justify" }}>
            {t("about.section3.desc3")}
          </div>

          <div className="text-24 text-semi-bold">
            {t("about.section3.subtitle4")}
          </div>
          <div style={{ padding: "12px 24px 44px 0", textAlign: "justify" }}>
            {t("about.section3.desc4")}
          </div>
        </div>
        <div
          className="mid-container d-flex flex-column px-s "
          style={{ paddingBottom: 35 }}
        >
          <div className="text-48 text-bold" style={{ paddingBottom: 35 }}>
            {t("about.section4.title")}
          </div>

          <div style={{ padding: "12px 24px 44px 0", textAlign: "justify" }}>
            {t("about.section4.subtitle")}
          </div>
        </div>
      </div>
    </div>
  );
}

import { RootState, AppDispatch } from "../../reducers/store";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import tick from "../../Assets/Icon/tick.svg";
import { toggleMailModal, setSubscribeSucceeded, resetMailModal } from "../../reducers/app";
import { postSubscribe } from "../../reducers/app.thunk";
import linkedIn from "../../Assets/Icon/linkedin-white.png";
import "./ModalMail.scss";
import { useState } from "react";

export default function ModalMail() {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const { mailModalOpened, subscribeSucceded, mailModalTitle, mailModalSubTitle } = useSelector(
    (state: RootState) => state.app
  );
  const [email, setEmail] = useState("");
  const [valid, setValid] = useState(false);

  function isValidEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleChange = (event: any) => {
    if (!isValidEmail(event.target.value)) {
      setValid(true)
    } else {
      setValid(false)
    }

    setEmail(event.target.value);
  };

  const handleLinkedInClick = () => {
    window.open(
      "https://www.linkedin.com/company/ticket-please/",
      "_blank",
      "noreferrer"
    );
  };

  const handleSubscribe = () => {
    dispatch(postSubscribe({ email: email }));
    dispatch(setSubscribeSucceeded(true));
  };

  const handleClose = () => {
    dispatch(toggleMailModal(false))
    dispatch(resetMailModal())
    dispatch(setSubscribeSucceeded(false));
    setEmail('')
  }
  return (
    <div className={`modal-wrapper ${mailModalOpened ? " displayed" : ""}`}>
      <div className="modal bg-white text-white">
        <span className="cross cursor" onClick={handleClose}>+</span>
        <div
          className="d-flex bg-black flex-column align-items-center justify-content-center"
          style={{ borderRadius: 50, minHeight: 460, padding: '24px 0 24px 0' }}
        >
          <div className="text-48 text-bold mt-s-24 px-s" style={{ textAlign: "center", whiteSpace: 'pre-line' }}>
            {t(mailModalTitle)}
          </div>
          <div
            className="text-20 text-wrapper"
            style={{
              textAlign: "center",
              maxWidth: '70%',
            }}
          >
            {t(mailModalSubTitle)}
          </div>
          <div>
            {!subscribeSucceded && (
              <input
                type="text"
                className="main-input placeholder-center"
                placeholder={t('common.inputEmailPlaceholder')}
                value={email}
                onChange={handleChange}
                style={{
                  width: 285,
                  textAlign: 'center'
                }}
              />
            )}
          </div>
          <div>
            {subscribeSucceded ? (
              <img
                src={tick}
                alt="ticket please"
                height="66"
                className="text-link"
                onClick={handleClose}
              />
            ) : (
              <button
                className="button-primary-pink text-semi-bold"
                style={{
                  width: 285,
                  marginTop: 10,
                }}
                disabled={valid || email === ''}
                onClick={handleSubscribe}
              >
                {t('common.continue')}
              </button>
            )}
          </div>
          <hr className="solid"></hr>
          <div>
            <img
              src={linkedIn}
              alt="ticket please"
              height="32"
              className="text-link mb-s-24"
              onClick={handleLinkedInClick}
            />
          </div>
        </div>
      </div>
      <div className="background" />
    </div>
  );
}

import { useTranslation } from "react-i18next";

export default function GeneralConditions() {
  const { t } = useTranslation();
  return (
    <div
      className="d-flex justify-content-center flex-column align-items-center"
      style={{ paddingTop: 64 }}
    >
      <div
        className="mid-container d-flex flex-column px-s"
        style={{ paddingTop: 70, paddingBottom: 35 }}
      >
        <div className="text-48 text-bold" style={{ paddingBottom: 35 }}>
          {t("conditions.title")}
        </div>

        <div style={{ paddingBottom: 35 }}>{t("conditions.subtitle")}</div>

        <div className="text-24 text-semi-bold">{t("conditions.a1.title")}</div>
        <div style={{ padding: "12px 24px 0 24px" }}>
          - {t("conditions.a1.subtitle1")}:
        </div>
        <div style={{ padding: "0 24px 24px 48px", textAlign: "justify" }}>
          {t("conditions.a1.desc1")}
        </div>

        <div style={{ padding: "0px 24px 0 24px" }}>
          - {t("conditions.a1.subtitle2")}:
        </div>
        <div style={{ padding: "0 24px 24px 48px", textAlign: "justify" }}>
          {t("conditions.a1.desc2")}
        </div>

        <div style={{ padding: "0px 24px 0 24px" }}>
          - {t("conditions.a1.subtitle3")}:
        </div>
        <div style={{ padding: "0 24px 24px 48px", textAlign: "justify" }}>
          {t("conditions.a1.desc3")}
        </div>

        <div style={{ padding: "0px 24px 0 24px" }}>
          - {t("conditions.a1.subtitle4")}:
        </div>
        <div style={{ padding: "0 24px 48px 48px", textAlign: "justify" }}>
          {t("conditions.a1.desc4")}
        </div>

        <div className="text-24 text-semi-bold">{t("conditions.a2.title")}</div>
        <div style={{ padding: "12px 24px 48px 24px", textAlign: "justify" }}>
          {t("conditions.a2.subtitle")}
        </div>

        <div className="text-24 text-semi-bold">{t("conditions.a3.title")}</div>
        <div style={{ padding: "12px 24px 48px 24px", textAlign: "justify" }}>
          {t("conditions.a3.subtitle")}
        </div>

        <div className="text-24 text-semi-bold">{t("conditions.a4.title")}</div>
        <div style={{ padding: "12px 24px 48px 24px", textAlign: "justify" }}>
          {t("conditions.a4.subtitle")}
        </div>

        <div className="text-24 text-semi-bold">{t("conditions.a5.title")}</div>
        <div style={{ padding: "12px 24px 48px 24px", textAlign: "justify" }}>
          {t("conditions.a5.subtitle")}
        </div>

        <div className="text-24 text-semi-bold">{t("conditions.a6.title")}</div>
        <div style={{ padding: "12px 24px 48px 24px", textAlign: "justify" }}>
          {t("conditions.a6.subtitle")}
        </div>
      </div>
    </div>
  );
}

import i18n from "i18next";
// Bindings for React: allow components to
// re-render when language changes.
import { initReactI18next } from "react-i18next";

i18n
	// Add React bindings as a plugin.
	.use(initReactI18next)
	// Initialize the i18next instance.
	.init({
		lng: "fr",
		fallbackLng: "fr",
		supportedLngs: ['en', 'fr'],
		debug: true,
		interpolation: {
			escapeValue: false,
		},
		resources: {
			// English
			en: {
				translation: require('./locales/en/translations.json')
			},
			// French
			fr: {
				translation: require('./locales/fr/translations.json')
			},
		},
	});

export default i18n;
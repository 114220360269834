import "./NavBar.scss";
import mainLogo from "../../Assets/Logo/ticket-please-logo-white.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toggleMailModal } from "../../reducers/app";
import globe from "../../Assets/Icon/globe.svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function NavBar() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation()
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [language, setLanguage] = useState("Français");

  const swicthLanguage = () => {
    if (language === "Français") {
      setLanguage("English");
      i18n.changeLanguage("en");
    } else {
      setLanguage("Français");
      i18n.changeLanguage("fr");
    }
  };
  return (
    <div
      className="bg-black d-flex justify-content-center text-white w-full"
      style={{
        height: 54,
        paddingTop: 5,
        paddingBottom: 5,
        position: "fixed",
        zIndex: 5,
      }}
    >
      <div
        className="full-container h-full w-full d-flex align-items-center justify-content-between"
        style={{ paddingLeft: 24, paddingRight: 24 }}
      >
        <div className="h-full flex-grow-1 w-full">
          <Link to="/">
            <img src={mainLogo} alt="ticket please" className="h-full" />
          </Link>
        </div>

        <div className="d-flex d-s-none flex-grow-1 w-full justify-content-between p-relative navigation">
          <div
            className={`cursor link link-1 p-relative w-full ${location.pathname === '/explorer/' ? 'link-1-selected' : ''}`}
            onClick={() => dispatch(toggleMailModal(true))}
          >
            {t("common.explore")}
          </div>
          <div
            className="cursor link link-2 p-relative w-full"
            onClick={() => dispatch(toggleMailModal(true))}
          >
            {t("common.book")}
          </div>
          <div
            className="cursor link link-3 p-relative w-full"
            onClick={() => dispatch(toggleMailModal(true))}
          >
            {t("common.travel")}
          </div>
          <div className="animated p-absolute" />
        </div>
        
        <div className="d-flex align-items-center flex-grow-1 w-full justify-content-end">
          <div className="d-flex align-items-center">
            <img src={globe} alt="ticket please" />
            <div
              className="cursor"
              style={{
                fontSize: 11,
                marginLeft: 4,
                marginRight: 26,
                minWidth: 40,
              }}
              onClick={swicthLanguage}
            >
              {language}
            </div>
          </div>
          <div style={{ fontSize: 14 }}>
            <button
              className="button-rounded-white cursor text-semi-bold"
              onClick={() => dispatch(toggleMailModal(true))}
            >
              {t("common.subscribe")}
            </button>
          </div>

          <div className="hamburger-lines">
            <input className="checkbox cursor" type="checkbox" name="" id="" />
            <span className="line line1"></span>
            <span className="line line3"></span>
            <div className="menu-items text-48 text-bold text-black bg-white">
          <div className="cursor" onClick={() => dispatch(toggleMailModal(true))}>
            {t("common.explore")}
          </div>
          <div className="cursor mt-24 mb-24" onClick={() => dispatch(toggleMailModal(true))}>
            {t("common.book")}
          </div>
          <div className="cursor" onClick={() => dispatch(toggleMailModal(true))}>
            {t("common.travel")}
          </div>
        </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useTranslation } from "react-i18next";

export default function LegalNotice() {
  const { t } = useTranslation();
  return (
    <div
      className="d-flex justify-content-center flex-column align-items-center"
      style={{ paddingTop: 64 }}
    >
      <div
        className="mid-container d-flex flex-column px-s"
        style={{ paddingTop: 70, paddingBottom: 35 }}
      >
        <div className="text-48 text-bold" style={{ paddingBottom: 35 }}>
        {t("legal.title")}
        </div>

        <div className="text-24 text-semi-bold">
          1. {t("legal.subtitle1")}
        </div>
        <div style={{ padding: "12px 24px 44px 24px", textAlign: "justify" }}>
        {t("legal.desc1")}
        </div>

        <div className="text-24 text-semi-bold">2. {t("legal.subtitle2")}</div>
        <div style={{ padding: "12px 24px 44px 24px", textAlign: "justify" }}>
        {t("legal.desc2")}
        </div>

        <div className="text-24 text-semi-bold">3. {t("legal.subtitle3")}</div>
        <div style={{ padding: "12px 24px 44px 24px", textAlign: "justify" }}>
        {t("legal.desc3")}
        </div>

        <div className="text-24 text-semi-bold">4. {t("legal.subtitle4")}</div>
        <div style={{ padding: "12px 24px 44px 24px", textAlign: "justify" }}>
        {t("legal.desc4")}
        </div>

        <div className="text-24 text-semi-bold">
          5. {t("legal.subtitle5")}
        </div>
        <div style={{ padding: "12px 24px 44px 24px", textAlign: "justify" }}>
        {t("legal.desc5")}
        </div>
      </div>
    </div>
  );
}

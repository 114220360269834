import { persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage
import appReducer from './app'


const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
}

export const rootReducer = {
  app: appReducer,
}

const persistedRootReducer = persistCombineReducers(persistConfig, rootReducer)

export default persistedRootReducer
